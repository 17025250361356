import request from '@/auth/jwt/request'

/**
 * @description 项目政策 -- 项目政策列表
 * @param {Object} param params {Object} 传值参数
 */
export const policyListApi = params => { return request('project-policy/list', 'get', params) }

/**
 * @description 项目政策 -- 项目下拉
 * @param {Object} param params {Object} 传值参数
 */
export const policyProjetSelectApi = () => { return request('project/select', 'get', null) }

/**
 * @description 项目政策 -- 增加修改项目
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const policySaveApi = data => { return request('project-policy/save', 'post', {}, data) }

/**
 * @description 项目政策 -- 项目状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const policyStatusApi = params => { return request('project-policy/status', 'get', params) }

/**
 * @description 项目政策 -- 项目详情
 * @param {Object} param params {Object} 传值参数
 */
export const policyInfoApi = params => { return request('project-policy/info', 'get', params) }
/**
 * @description 项目政策 -- 删除项目
 * { id = x }
 */
export const policyDeleteApi = data => { return request('project-policy/delete', 'delete', data) }